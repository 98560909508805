/* ======================================================================
 utility
====================================================================== */

/* =================================
 layout
================================= */
// clearfix
.u_cf {
  @include cf;
}

.u_osw {
  font-family: 'Oswald',serif;
}
.u_lato {
  font-family: 'Lato',sans-serif;
}

// pc・sp 出し分け（media-queryはサイトの仕様に合わせて変更すること）
@include pc_tab {
  .u_pc {
    display: block;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }
  .u_sp {
    display: none;
    &_i,
    &_ib,
    &_f,
    &_if {
      display: none;
    }
  }
}

@include sp {
  .u_pc {
    display: none;
    &_i,
    &_ib,
    &_f,
    &_if {
      display: none;
    }
  }
  .u_sp {
    display: block;
    &_i {
      display: inline;
    }
    &_ib {
      display: inline-block;
    }
    &_f {
      display: flex;
    }
    &_if {
      display: inline-flex;
    }
  }
}

/* =================================
 content_size
================================= */
.u_content_size {
  padding: 0 $sp_side_padding;
  @include pc_tab {
    padding: 0;
    margin: 0 auto;
    width: $content_width;
  }
}

/* =================================
 text
================================= */
.u_txt_1 {
  font-size: 1.6em;
  line-height: 2.3;
  letter-spacing: .1em;
  @include pc_tab {
  }
}

.u_txt_2 {
  font-size: 1em;
  line-height: 1;
  letter-spacing: .1em;
  @include pc_tab {
  }
}

.u_txt_3 {
  font-size: 1em;
  line-height: 1;
  letter-spacing: .1em;
  @include pc_tab {
  }
}
.u_txt_14-1 {
  font-size: 14px;
  line-height: calc(28 / 14);
  letter-spacing: .1em;
  @include pc_tab {
    font-size: 14px;
    line-height: calc(28 / 14);
  }
}
.u_txt_26-1 {
  font-size: 20px;
  line-height: calc(30 / 20);
  letter-spacing: .1em;
  @include pc_tab {
    font-size: 26px;
    line-height: calc(27 / 26);
  }
}
.u_txt_26-2 {
  font-size: 26px;
  line-height: calc(30 / 26);
  letter-spacing: .1em;
  @include pc_tab {
    font-size: 26px;
    line-height: calc(27 / 26);
  }
}
.u_txt_26-3 {
  font-size: 18px;
  line-height: calc(30 / 18);
  letter-spacing: .1em;
  @include pc_tab {
    font-size: 26px;
    line-height: calc(44 / 26);
  }
}

.u_txt_18-1 {
  font-size: 14px;
  line-height: calc(28 / 14);
  letter-spacing: .1em;
  @include pc_tab {
    font-size: 18px;
    line-height: calc(27 / 18);
  }
}
.u_txt_35-1 {
  font-size: 35px;
  line-height: calc(28 / 35);
  letter-spacing: .1em;
  @include pc_tab {
  }
}

/* =================================
 text weight
================================= */
.u_lighter {
  font-weight: lighter;
}

.u_bold {
  font-weight: bold;
}

/* =================================
 text align
================================= */
.u_ta_c {
  text-align: center;
}

.u_ta_cl {
  text-align: center;
  @include pc_tab {
    text-align: left;
  }
}

.u_ta_lc {
  text-align: left;
  @include pc_tab {
    text-align: center;
  }
}

/* =================================
 paragraph margin
================================= */
.u_paragraph {
  & + .u_paragraph {
    margin: 15px 0 0;
  }
}

/* =================================
 external link
================================= */
.u_ext {
  margin-left: 5px;

  &:before{
    content: "";
    display: inline-block;
    background: url(../img/common/ext.svg) no-repeat;
    background-size: cover;
    vertical-align: -2px;
  }

}

/* =================================
 u_anim01
================================= */
.u_anim01 {
  animation: move-plane 4s ease 0s infinite alternate none running;
}

@keyframes move-plane {
  0% {
    bottom: 80px;
  }

  50% {
    bottom: 100px;
  }

  100% {
    bottom: 80px;
  }
}

@keyframes shooting {
  0% {
    height: 0;
    opacity: 50%;
  }
  5% {
    height: 150px;
    opacity: 100%;
  }
  8% {
    height: 200px;
    opacity: 0;
  }
  100% {
    height: 200px;
    opacity: 0;
  }
}

/* ===========================================
* u_color
* ======================================== */
.u_color_white {
  color: $white;
}

.u_color_2 {
  color: $color_2;
}

.u_color_6 {
  color: $color_6;
}

