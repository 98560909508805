/* ======================================================================
 component
====================================================================== */

/* =================================
 title
================================= */
.c_ttl_1 {
  position: relative;
  margin: 0 0 30px 0;
  padding: 0 0 25px 0;
  font-size: 32px;
  font-weight: bold;
  color: $white;

  @include mq_max(768) {
    padding: 0 0 20px 0;
    font-size: 22px;
    line-height: 1.6;
  }

  &:after{
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 100px;
    height: 5px;
    background: $color_1;

    @include mq_max(768) {
      width: 50px;
      height: 3px;
    }

  }

  a{
    color: #fff;
    font-size: 28px;

    @include mq_max(768) {
      font-size: 18px;
    }

    .u_ext:before{
      width: 21px;
      height: 21px;

      @include mq_max(768) {
        width: 12px;
        height: 12px;
      }

    }

  }

}

.c_ttl_2 {
}

.c_ttl_3 {
}

/* =================================
 buttons
================================= */
// c_gotop
.c_gotop {
}

.c_btn_1 {
  width: 315px;
  height: 45px;
  background: #C8AF42;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 45px;
  >span {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.12px;
    padding: 0 0 0 10px;
    color: #fff;
  }
}

.c_btn_2,
a.c_btn_2{
  display: inline-block;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  border: 1px solid #fff;
  background: url(../img/common/btn-img.svg) no-repeat right 12px top 50% rgba(#fff,.15);
  background-size: 18px auto;
  border-radius: 3px;
  line-height: 58px;
  text-align: center;
  font-size: 15px;
  color: #fff;

  @include mq_max(768) {
    background-position: right 6px top 50%;
  }

  &:hover{
    background-image: url(../img/common/btn-img-hover.svg);
    background-position: right 5px top 50%;
    background-size: 30px auto;
    color: $color_1;
    font-weight: bold;
  }

}


/* =================================
 table
================================= */
.c_table_1 {
  width: calc(100% + 20px);
  margin: 50px -10px 40px;
  border-collapse: separate;
  border-spacing: 10px;

  @include mq_max(768) {
    margin-top: 30px;
    margin-bottom: 0;
    width: 100%;
    margin: 30px 0 0;
  }

  th,td{
    padding: 15px 15px 5px;
    font-size: 16px;
    line-height: 1.7;

    @include mq_max(768) {
      padding: 10px 0 0;
      font-size: 14px;
      line-height: 1.5;
    }

  }

  thead{

    th{
      color: $color_2;
      font-size: 18px;
      font-weight: bold;

      @include mq_max(768) {
        font-size: 14px;
      }

    }

  }

  tbody{

    th{
      width: 12em;
      border-top: 1px solid #C9C9C9;
      font-weight: bold;

      @include mq_max(768) {
        width: auto;
        min-width: 5.5em;
      }

    }
    td{
      border-top: 1px solid #707070;
      white-space: nowrap;
      @include mq_max(768) {
        white-space: normal;
      }

      a{
        color: #fff;
        text-decoration: underline;
      }

    }

  }

  tbody tr:last-of-type{

    th{
      padding-bottom: 15px;
      border-bottom: 1px solid #C9C9C9;
    }
    td{
      padding-bottom: 15px;
      border-bottom: 1px solid #707070;
    }

  }

  &-withlist{

    td{
      padding-left: 25px;

      @include mq_max(768) {
        padding-left: 0;
      }

    }

    dl{
      margin-left: -25px;

      @include mq_max(768) {
        margin-left: 0;
      }

      dt{
        margin-bottom: 10px;
        padding-left: 25px;
        background: url(../img/common/list.svg) no-repeat left center;
        font-weight: bold;
      }
      dd{
        padding-left: 25px;

        &+dt{
          margin-top: 25px;
        }

      }

    }

    ul{
      margin-left: -25px;

      @include mq_max(768) {
        margin-left: 0;
      }

      li{
        padding-left: 25px;
        background: url(../img/common/list.svg) no-repeat left center;
      }

    }

  }

}

/* =================================
 list
================================= */
// c_list_1
.c_list_1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include mq_max(768) {
    display: block;
  }

  li{
    padding: 15px;
    width: 410px;
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    margin-bottom: -1px;
    font-size: 16px;
    font-weight: bold;

    @include mq_max(768) {
      width: auto;
      font-size: 14px;
    }

  }

}


/* =================================
 pager
================================= */

.c_pager_prev_next {

  ul {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    .back {
      width: 290px;
      margin: 100px auto 0;
      @include mq_max(768) {
        width: 100%;
        padding: 0 30px;
        margin: 30px auto 0;
      }
      >a {
        >span {
          color: #fff;
          transition: color .2s linear;
        }
        &:hover {
          >span {
            color: $color_1;
          }
        }
      }
    }
  }
}

/* =================================
 backbtn
================================= */
.c_backbtn {
  width: 450px;
  margin: 100px auto 0;

  @include mq_max(768) {
    width: auto;
    margin: 50px 30px 0;

    .c_btn_2{
      font-size: 12px;
    }

  }

}

/* =================================
 basicblock
================================= */
.c_basicblock {
  position: relative;
  margin: 100px 0 0 0;
  padding: 0 0 0 150px;
  color: #fff;

  @include mq_max(768) {
    margin-top: 50px;
    padding: 0 30px;
    overflow: hidden;
  }

  &:first-of-type{
    margin-top: 0;
  }

  *{
    position: relative;
    z-index: 3;
  }

  &:before{
    content: attr(data-lead);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    display: block;
    width: 100%;
    transform: rotate(270deg) translateY(-1150px);
    transform-origin: right top;
    font-family: $font_2;
    font-size: 120px;
    line-height: 90px;
    color: rgba(#707070,.25);
    text-align: right;
    pointer-events: none;

    @include mq_max(768) {
      left: auto;
      top: 350px;
      right: 74px;
      white-space: nowrap;
      font-size: 87px;
      line-height: 65px;
      transform: rotate(270deg) translateY(0);
    }

  }

  &._introduction {
    &:before{
      @include mq_max(768) {
        top: 30px;
      }
    }
  }
  &._institutions {
    &:before{
      @include mq_max(768) {
        top: 90px;
      }
    }
  }
  &._licenses {
    &:before{
      @include mq_max(768) {
        top: 30px;
      }
    }
  }

  &._organization {
    @include mq_max(768) {
      padding-left: 30px;
      padding-right: 15px;
    }
    .swip {
      @include mq_max(768) {
        width: 190px;
        padding: 0 0 20px;
      }
    }
    .scroll {
      @include mq_max(768) {
        overflow-x: scroll;
        img {
          width: 540px;
          max-width: initial;
        }
      }
    }
  }

  &._right_text {
    &:before{
      content: attr(data-lead);
      position: absolute;
      right: 0;
      left: initial;
      top: 60px;
      z-index: 2;
      display: block;
      width: 100%;
      transform: rotate(90deg) translate(100%,-40%);
      transform-origin: right top;
      font-family: $font_2;
      font-size: 120px;
      line-height: 90px;
      color: rgba(#707070,.25);
      text-align: left;
      pointer-events: none;

      @include mq_max(768) {
        left: auto;
        top: 250px;
        right: 74px;
        font-size: 87px;
        line-height: 65px;
        transform: rotate(270deg) translateY(0);
        text-align: right;
        white-space: nowrap;
      }

    }

  }

  &-right{
    padding: 0 120px 0 0;

    @include mq_max(768) {
      padding: 0 30px;
    }

    &:before{
      top: 0;
      transform: rotate(90deg) translateY(-1150px);
      transform-origin: left top;
      text-align: left;

      @include mq_max(768) {
        left: auto;
        right: 74px;
        top: 0;
        font-size: 87px;
        line-height: 65px;
        transform: rotate(270deg) translateY(0);
        transform-origin: right top;
        text-align: right;
      }

    }

  }

  &-bg{
    padding: 60px;
    background: $color_3;

    @include mq_max(768) {
      position: relative;
      background: none;
      padding: 20px 15px;

      &:after{
        position: absolute;
        left: 15px;
        top: 0;
        width: calc(100% - 30px);
        height: 100%;
        content: "";
        background: $color_3;
      }

    }

  }

  &_inner{
    display: flex;
    justify-content: space-between;

    @include mq_max(768) {
      display: block;
    }

  }
  &_sub{

    @include mq_max(768) {
      margin-top: 30px;
    }

    &+.c_basicblock_sub{
      margin-top: 50px;

      @include mq_max(768) {
        margin-top: 30px;
      }

    }

    h3{
      font-size: 26px;
      font-weight: bold;
      color: $color_2;

      @include mq_max(768) {
        font-size: 18px;
      }

    }

  }

  p{
    margin: 30px 0 0 0;
    font-size: 16px;
    line-height: 1.7;

    @include mq_max(768) {
      font-size: 14px;
      line-height: 2;
    }

  }

}
