/* ======================================================================
 layout
====================================================================== */

.p_under {
  background: #000;
  position: relative;
  overflow: hidden;
  &.l_container {
    background: none;
  }
  &:before {
    content: '';
    background: url(../img/common/under.png);
    background-size: cover;
    width: 2463px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    @include mq_max(768) {
      width: 100%;
      height: 100%;
      background: url(../img/common/sp/under.png);
      background-size: cover;
      background-color: #000;
    }
  }
  &._pt2 {
    &:before {
      content: '';
      background: url(../img/common/under_pt2.png);
      background-size: cover;
      @include mq_max(768) {
        width: 100%;
        height: 100%;
        background: url(../img/common/sp/under_pt2.png);
        background-size: cover;
        background-color: #000
      }
    }
  }
  &._sitemap {
    &:before {
      content: '';
      background: url(../img/common/under_sitemap.png);
      background-size: cover;
      @include mq_max(768) {
        width: 100%;
        height: 100%;
        background: url(../img/common/sp/under_sitemap.png);
        background-size: cover;
      }
    }
  }
  .l_content {
    &:before {
      display: none;
    }
  }
  .l_footer {
    background: none;
    background-color: transparent;
    &:before {
      display: none;
    }
  }
}

/* =================================
 l_container
================================= */
.l_container {
  background: url(../img/common/main_bg.png) repeat-y 0 400px #000;
  background-size: 100% auto;
}

.l_main {
  width: 1100px;
  margin: 0 auto;
  margin: 60px auto 0;
  position: relative;
  z-index: 2;
  @include mq_max(768) {
    width: 100%;
  }
  &._news_archive {
    margin: 70px auto 0;
    background: rgba(221,221,221,.1);
    @include mq_max(768) {
      width: calc( 100% - 60px);
      margin: 50px auto 0;
    }
  }

}

.l_content {
  z-index: 1;
}

/* =================================
 l_header
================================= */
.l_header {
  width: 90px;
  height: 175px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 100;
  @include mq_max(768) {
    width: 50px;
    height: 50px;
    top: 10px;
    right: 10px;
  }
  .menu {
    cursor: pointer;
    text-align: center;
    img {
      margin: 0 auto;
      pointer-events: all;
    }
  }
  .mail {
    margin: 15px 0 0;
    @include mq_max(768) {
      display: none;
    }
    >a {
      width: 100%;
      height: 100%;
      display: block;
      transition: all .2s linear;
      &:hover {
        opacity: .7;
      }
    }
  }
}

.l_header_en {
  width: 90px;
  height: 175px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 100;
  @include mq_max(768) {
    width: 50px;
    height: 50px;
    top: 10px;
    right: 10px;
  }
  .menu {
    cursor: pointer;
    text-align: center;
    img {
      margin: 0 auto;
      pointer-events: all;
    }
  }
  .mail {
    margin: 15px 0 0;
    @include mq_max(768) {
      display: none;
    }
    >a {
      width: 100%;
      height: 100%;
      display: block;
      transition: all .2s linear;
      &:hover {
        opacity: .7;
      }
    }
  }
}

.l_header_chn {
  width: 90px;
  height: 175px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 100;
  @include mq_max(768) {
    width: 50px;
    height: 50px;
    top: 10px;
    right: 10px;
  }
  .menu {
    cursor: pointer;
    text-align: center;
    img {
      margin: 0 auto;
      pointer-events: all;
    }
  }
  .mail {
    margin: 15px 0 0;
    @include mq_max(768) {
      display: none;
    }
    >a {
      width: 100%;
      height: 100%;
      display: block;
      transition: all .2s linear;
      &:hover {
        opacity: .7;
      }
    }
  }
}

.menubox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  display: none;
  .wrap {
    width: 100vw;
    height: 100vh;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    justify-content: flex-end;
    background: rgba(0,0,0,.8);
  }
  .menuarea {
    width: 410px;
    height: 100vh;
    background: rgba(255,255,255,.9);
    padding: 270px 0 0 0;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    @include mq_max(768) {
      display: none;
    }
    ul {
      width: 100%;
      padding: 0 0 0 70px;
      li {
        &:not(:first-child) {
          margin: 40px 0 0;
        }
        a {
          color: #030327;
          font-size: 16px;
          font-weight: bold;
          transition: all .2s linear;
          &:hover {
            opacity: .7;
          }
        }
      }
    }
  }
  .snsbox {
    align-self: flex-end;
    width: 100%;
    background: #030327;
    padding: 50px 0 30px;
    >ul {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: center;
      padding: 0 0 30px;
      align-items: center;
      >li {
        &:not(:first-child) {
          margin: 0 0 0 10px;
        }
      }
    }
    .txtlink {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: center;
      >a {
        color: #fff;
        display: inline-block;
        font-size: 13px;
        &:hover {
          text-decoration: underline;
        }
        &:not(:first-child) {
          margin: 0 0 0 10px;
          position: relative;
          &:before {
            content: '';
            width: 1px;
            height: 8px;
            background: #fff;
            position: absolute;
            left: -5px;
            top: 3px;
          }
        }
      }
    }
  }
  .u_sp {
    width: 100%;
    >.logo {
      padding: 17px 0 0 30px;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      p {
        text-align: right;
      }
      >a {
        display: inline-block;
        >img {
          width: 210px;
        }
      }
    }

    >.lang {
      margin: 25px 0;
      padding: 0 0 0 30px;
      .border {
        width: 158px;
        height: 44px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        border: 1px solid #fff;
        >a {
          width: 50%;
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          color: #fff;
          &:first-child {
            position: relative;
            &:before {
              content: '';
              width: 1px;
              height: 22px;
              background: #fff;
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
            }
          }
        }
      }
    }

    >.cvbox {
      width: 100%;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      padding: 20px 17px 0 30px;
      .box {
        padding: 5px;
        background: #fff;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
        >a {
          &:first-child {
            margin: 0 5px 0 0;
          }
          img {
            width: 156px;
          }
        }
      }
    }
    .subtxt {
      font-size: 10px;
      color: #fff;
      padding: 5px 0 10px 40px;
      text-align: left;
    }
    .scroll {
      padding: 20px 0 0 30px;
      height: calc(100% - 305px);
      overflow-y: scroll;
      >ul {
        li {
          &:not(:first-child) {
            border-top: 1px solid #fff;
          }
          >a {
            position: relative;
            &:before {
              position: absolute;
              top: 50%;
              right: 20px;
              transform: translateY(-50%);
              content: url(../img/common/sp/arrow.svg);
            }
          }
          >a,
          >p {
            background: rgba(24,24,72,.9);
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            align-items: center;
            padding: 23px 20px;
            font-size: 14px;
            color: #fff;
            &._active {
              color: #C8AF42;
              font-weight: bold;
            }
          }
          >.sns {
            padding: 15px 0;
            background: rgba(24,24,72,.9);
            display: -webkit-flex;
            display: -ms-flex;
            display: flex;
            justify-content: center;
            >a {
              display: -webkit-flex;
              display: -ms-flex;
              display: flex;
              align-items: center;
              &:not(:first-child) {
                margin: 0 0 0 20px;
              }
            }
          }
          &._active {
            p {
              background: rgba(79,79,103,.8);
            }
          }
          ul {
            border-top: 1px solid #fff;
            display: none;
            >li {
              a {
                background: rgba(79,79,103,.8);
                padding: 23px 20px 23px 40px;
              }
            }
          }
        }
      }
    }
  }
}

.float_sns {
  position: fixed;
  bottom: 100px;
  left: 50px;
  z-index: 4;
  @include mq_max(768) {
    display: none;
  }
  >ul {
    >li {
      &:not(:first-child) {
        margin: 15px 0 0;
        &.another {
          margin: 10px 0 0;
        }
      }
      >a {
        display: block;
        text-align: left;
        transition: all .2s linear;
        &:hover {
          opacity: .7;
        }
        >.sns {
          display: inline-block;
          padding: 0 0 0 5px;
        }
      }
    }
  }
}

/* =================================
 l_breadcrumbs
================================= */
.l_breadcrumbs {
  width: 1100px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
  @include mq_max(768) {
    width: calc(100% - 60px);
    margin: 0 auto;
  }
  .breadcrumbs {
    @include mq_max(768) {
      line-height: 1.3;
    }
    span {
      span {
        color: #fff;
        font-size: 13px;
        >a {
          font-size: 13px;
          color: #fff;
          display: inline-block;
          padding: 0 25px 0 0;
          position: relative;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
          &:before {
            position: absolute;
            content: '';
            top: 2px;
            right: 11px;
            width: 1px;
            height: 10px;
            background: #fff;
          }
        }
      }
    }
    >span>span>a{
      color: #C8AF42;
    }
  }
}

/* =================================
 l_main_visual
================================= */
.l_main_visual {
}


/* =================================
 l_main_visual_top
================================= */

.force-contents {
  position: fixed;
  background: #000;
  width: 100%;
  height: 100%;
  z-index: 100000;
  transition: background-color 1s linear;
  &._fadeout {
    background: #fff;
  }
  .skip {
    position: absolute;
    z-index: 100015;
    cursor: pointer;
    top: 50px;
    right: 15px;
    color: #fff;
  }
}
video {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100010;
}

.l_main_visual_top {
  width: 100vw;
  height: 100vh;
  background: url(../img/p_top/mvbg.png);
  background-size: cover;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @include mq_max(768) {
    background: url(../img/p_top/sp/mvbg.png);
    background-size: cover;
  }

  #particles-js {
    position:absolute;;/*描画固定*/
    z-index: 2;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  #player {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100000;
  }

  .star-box {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 200px;
    width: 200px;
    transform: rotateZ(56deg);
    @include mq_max(768) {
      width: 150px;
      height: 150px;
    }
  }
  .star {
    height: 0;
    width: 2px;
    background: linear-gradient(transparent, rgb(255, 255, 255));
    animation-name: shooting;
    animation-duration: 8s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  .logo {
    position: absolute;
    top: 30px;
    left: 50px;
    @include mq_max(768) {
      top: 10px;
      left: 10px;
      width: calc(100% - 10px);
    }
    >p {
      a {
        display: inline-block;
        img {
          width: 466px;
          @include mq_max(768) {
            width: 272px;
          }
        }
      }
    }
    .lang {
      margin: 20px 0 0;
      width: 110px;
      height: 30px;
      border: 1px solid #fff;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mq_max(768) {
        width: 50px;
        height: 54px;
        display: block;
        position: absolute;
        top: 60px;
        right: 10px;
        margin: 0;
      }
      .u_lato {
        font-size: 16px;
        color: #fff;
        width: 50%;
        height: 100%;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .2s linear;
        @include mq_max(768) {
          width: 100%;
          font-size: 14px;
          height: 50%;
        }
        &:hover {
          opacity: .7;
        }
        &._second {
          position: relative;
          &:before {
            content: '';
            width: 1px;
            height: 20px;
            position: absolute;
            background: #fff;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            @include mq_max(768) {
              width: 80%;
              height: 1px;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  .maintxt {
    font-size: 11vw;
    color: #fff;
    white-space: nowrap;
    @include mq_max(768) {
      text-align: center;
      font-size: 70px;
      >span {
        font-size: 100px;
      }
    }
  }
  .scroll {
    position: absolute;
    bottom: 5px;
    left: 50%;
    width: 100px;
    height: 80px;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    transform: translateX(-50%);
    @include mq_max(768) {
      display: none;
    }
    &:hover {
      .wrap {
        .u_lato {
          letter-spacing: .18em;
        }
        &:before {
          bottom: 0;
        }
      }
      .u_anima2 {
        transform: rotateY(-360deg);
      }
    }
    .wrap {
      width: 100%;
      height: 100%;
      position: relative;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .u_lato {
        color: #fff;
        font-size: 13px;
        padding: 0 0 25px;
        letter-spacing: .05em;
        transition: all .8s ease;
      }
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        border-right: 2px solid rgba(255,255,255,.8);
        border-bottom: 2px solid rgba(255,255,255,.8);
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        transition: all .8s ease;
      }
    }
  }
  .airplane {
    position: absolute;
    width: 100px;
    left: -1%;
    bottom: 80px;
    &.u_anima2 {
      transition: all .8s linear;
    }
  }
}


/* =================================
 l_main_visual_subf
================================= */
.l_main_visual_sub {
  //width: 100vw;
  width: 100%;
  height: 400px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  @include mq_max(768) {
    height: auto;
  }
  &._pt2 {
    background: url(../img/common/header_bg.png) no-repeat center bottom;
    background-size: cover;
    @include mq_max(768) {
      background: url(../img/common/sp/header_bg@2x.png) no-repeat center bottom;
      background-size: cover;
      height: 260px;
    }
  }
  .logo {
    position: absolute;
    top: 30px;
    left: 50px;
    @include mq_max(768) {
      top: 10px;
      left: 10px;
      width: calc(100% - 10px);
    }
    >p {
      a {
        display: inline-block;
        img {
          width: 466px;
          @include mq_max(768) {
            width: 272px;
          }
        }
      }
    }
    .lang {
      margin: 20px 0 0;
      width: 110px;
      height: 30px;
      border: 1px solid #fff;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mq_max(768) {
        width: 50px;
        height: 54px;
        display: block;
        position: absolute;
        top: 60px;
        right: 10px;
        margin: 0;
      }
      .u_lato {
        font-size: 16px;
        color: #fff;
        width: 50%;
        height: 100%;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .2s linear;
        @include mq_max(768) {
          width: 100%;
          font-size: 14px;
          height: 50%;
        }
        &:hover {
          opacity: .7;
          @include mq_max(768) {
            opacity: 1;
          }
        }
        &._second {
          position: relative;
          &:before {
            content: '';
            width: 1px;
            height: 20px;
            position: absolute;
            background: #fff;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            @include mq_max(768) {
              width: 80%;
              height: 1px;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }
  >.inner {
    width: 1100px;
    height: 100%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    @include mq_max(768) {
      width: 100%;
      height: auto;
      padding: 0 0 0 30px;
      display: block;
      padding: 150px 0 30px 30px;
    }
  }
  .mainttl {
    .u_osw {
      font-size: 60px;
      color: #fff;
      @include mq_max(768) {
        font-size: 35px;
      }
    }
    .jp {
      margin: 20px 0 0;
      color: #fff;
      display: inline-block;
      font-size: 20px;
      font-weight: bold;
      @include mq_max(768) {
        font-size: 16px;
        margin: 5px 0 0;
      }
    }
  }
}

/* =================================
 l_content
================================= */
.l_content {
  position: relative;

  // &:after{
  //   content: "";
  //   position: absolute;
  //   left: 0;
  //   bottom: 0;
  //   display: block;
  //   width: 100%;
  //   height: 150px;
  //   background: linear-gradient(rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
  // }

}

/* =================================
 l_main
================================= */
.l_main {
}

/* =================================
 l_side
================================= */
.l_side {
}

/* =================================
 l_footer
================================= */
.l_footer {
  padding: 280px 0 0;
  background: #000;
  position: relative;
  overflow: hidden;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content:'';
    background: url(../img/common/footerbg@2x.png);
    background-size: cover;
    width: 1920px;
    height: 1322px;
    z-index: 1;
  }
  @include mq_max(768) {
    padding: 0;
  }
  .p_about &,
  .p_profile &,
  .p_message &,
  .p_history &,
  .p_service &,
  .p_machine &,
  .p_efforts &{
    padding-top: 100px;
    @include mq_max(768) {
      padding-top: 60px;
    }
  }

  >.l_cv {
    position: relative;
    z-index: 2;
    width: 1100px;
    margin: 0 auto;
    padding: 0 0 160px;
    @include mq_max(768) {
      width: 100%;
      padding: 0 0 30px;
    }
  }
  >.inner {
    position: relative;
    z-index: 2;
    margin: 0 auto;
    width: 1100px;
    @include mq_max(768) {
      width: 100%;
      padding: 20px 30px 0;
      background: transparent -webkit-linear-gradient(rgba(#181848,0) 0%, #000014 100%) 0% 0% no-repeat padding-box;
      background: transparent -o-linear-gradient(rgba(#181848,0) 0%, #000014 100%) 0% 0% no-repeat padding-box;
      background: transparent linear-gradient(rgba(#181848,0) 0%, #000014 100%) 0% 0% no-repeat padding-box;
    }
    .ft-logo {
      a {
        @include mq_max(768) {
          display: inline-block;
        }
        >img {
          width: 466px;
        }
      }
    }
    .l_flex {
      margin: 28px 0 0;
      padding: 0 0 0 25px;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      @include mq_max(768) {
        flex-wrap: wrap;
        padding: 0;
      }
      .col {
        a {
          color: #fff;
        }
      }
      .col1 {
        width: 293px;
        @include mq_max(768) {
          width: 100%;
        }
      }
      .col2 {
        width: 610px;
        @include mq_max(768) {
          width: 100%;
          display: none;
        }
        .ftmenu {
          margin: 15px 0 0;
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          flex-wrap: wrap;
          >li {
            width: 50%;
            &:nth-child(n+3) {
              margin: 15px 0 0;
            }
            &.max {
              width: 100%;
            }
            >a {
              font-size: 12px;
              position: relative;
              padding: 0 0 0 10px;
              &:hover {
                text-decoration: underline;
              }
              &:before {
                position: absolute;
                content: '';
                width: 5px;
                height: 1px;
                background: #fff;
                top: 10px;
                left: 0;
              }
            }
          }
        }
      }
      .col3 {
        @include mq_max(768) {
          width: 100%;
          margin: 30px 0 0;
        }
        .ttl-link {
          &:not(:first-child) {
            margin: 22px 0 0;
            @include mq_max(768) {
              margin: 30px 0 0;
            }
          }
          &.pdf {
            a {
              padding: 0 15px 0 0;
              position: relative;
              &:before {
                width: 15px;
                height: 20px;
                background: url(../img/common/ico-pdf.png);
                content: '';
                background-size: cover;
                position: absolute;
                top: 2px;
                right: 0;
              }
            }
          }
        }
      }
      .ttl-link {
        >a {
          font-size: 16px;
          font-weight: bold;
          white-space: nowrap;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .bottom {
      padding: 120px 0 10px;
      text-align: right;
      @include mq_max(768) {
        padding: 30px 0 10px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
      }
      >a {
        color: #fff;
        font-size: 12px;
        white-space: nowrap;
        &:hover {
          text-decoration: underline;
        }
        &:nth-child(1),
        &:nth-child(2) {
          margin: 0 40px 0 0;
          position: relative;
          @include mq_max(768) {
            margin: 0 20px 0 0;
          }
          &:before {
            position: absolute;
            content: '';
            width: 1px;
            height: 8px;
            background: #fff;
            top:6px;
            right: -20px;
            @include mq_max(768) {
              top: 4px;
              right: -11px;
            }
          }
        }
      }
    }
    .sns {
      margin: 40px 0 0;
      justify-content: center;
      >a {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        &:not(:first-child) {
          margin: 0 0 0 40px;
        }
      }
    }
  }
  .copy {
    height: 34px;
    background: #000;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items:center;
    justify-content: center;
    position: relative;
    z-index: 2;
    @include mq_max(768) {
      height: 47px;
    }
    >p {
      text-align: center;
      color: #fff;
    }
  }
}

.l_footer_top {
  padding: 200px 0 0;
  background-color: #000;
  position: relative;
  overflow: hidden;
  @include mq_max(768) {
    padding: 20px 30px 0;
    background: transparent -webkit-linear-gradient(rgba(#181848,0) 0%, #000014 100%) 0% 0% no-repeat padding-box;
    background: transparent -o-linear-gradient(rgba(#181848,0) 0%, #000014 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(rgba(#181848,0) 0%, #000014 100%) 0% 0% no-repeat padding-box;

  }
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 1920px;
    height: 600px;
    z-index: 1;
    background: url(../img/p_top/topfooter.png);
    background-size: contain;
    @include mq_max(768) {
      display: none;
    }
  }
  >.inner {
    position: relative;
    z-index: 2;
    margin: 0 auto;
    width: 1100px;
    @include mq_max(768) {
      width: 100%;
    }
    .ft-logo {
      a {
        @include mq_max(768) {
          display: inline-block;
        }
        >img {
          width: 466px;
        }
      }
    }
    .l_flex {
      margin: 28px 0 0;
      padding: 0 0 0 25px;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      @include mq_max(768) {
        flex-wrap: wrap;
        padding: 0;
      }
      .col {
        a {
          color: #fff;
        }
      }
      .col1 {
        width: 293px;
        @include mq_max(768) {
          width: 100%;
        }
      }
      .col2 {
        width: 610px;
        @include mq_max(768) {
          width: 100%;
          display: none;
        }
        .ftmenu {
          margin: 15px 0 0;
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          flex-wrap: wrap;
          >li {
            width: 50%;
            &:nth-child(n+3) {
              margin: 15px 0 0;
            }
            >a {
              font-size: 12px;
              position: relative;
              padding: 0 0 0 10px;
              &:hover {
                text-decoration: underline;
              }
              &:before {
                position: absolute;
                content: '';
                width: 5px;
                height: 1px;
                background: #fff;
                top: 10px;
                left: 0;
              }
            }
          }
        }
      }
      .col3 {
        @include mq_max(768) {
          width: 100%;
          margin: 30px 0 0;
        }
        .ttl-link {
          &:not(:first-child) {
            margin: 22px 0 0;
            @include mq_max(768) {
              margin: 30px 0 0;
            }
          }
          &.pdf {
            a {
              padding: 0 15px 0 0;
              position: relative;
              &:before {
                width: 15px;
                height: 20px;
                background: url(../img/common/ico-pdf.png);
                content: '';
                background-size: cover;
                position: absolute;
                top: 2px;
                right: 0;
              }
            }
          }
        }
      }
      .ttl-link {
        >a {
          font-size: 16px;
          font-weight: bold;
          white-space: nowrap;
          transition: all .2s linear;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .bottom {
      padding: 120px 0 10px;
      text-align: right;
      @include mq_max(768) {
        padding: 30px 0 10px;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
      }
      >a {
        color: #fff;
        font-size: 12px;
        white-space: nowrap;
        &:hover {
          text-decoration: underline;
        }
        &:nth-child(1),
        &:nth-child(2) {
          margin: 0 40px 0 0;
          position: relative;
          @include mq_max(768) {
            margin: 0 20px 0 0;
          }
          &:before {
            position: absolute;
            content: '';
            width: 1px;
            height: 8px;
            background: #fff;
            top: 6px;
            right: -20px;
            @include mq_max(768) {
              top: 4px;
              right: -11px;
            }
          }
        }
      }
    }

    .sns {
      margin: 40px 0 0;
      justify-content: center;
      >a {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        &:not(:first-child) {
          margin: 0 0 0 40px;
        }
      }
    }
  }
  .copy {
    position: relative;
    z-index: 2;
    height: 34px;
    background: #000;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items:center;
    justify-content: center;
    @include mq_max(768) {
      height: 47px;
    }
    >p {
      text-align: center;
      color: #fff;
    }
  }
}

.l_cv {
  position: relative;
  z-index: 2;
  margin: 150px auto 0;
  @include mq_max(768) {
    margin: 60px 0 0;
  }
  .cv {
    background: #fff;
    padding: 15px;
    @include mq_max(768) {
      padding: 30px;
      background: rgba(221,221,221,.1);
    }
    .l_flex {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      @include mq_max(768) {
        flex-wrap: wrap;
      }
      >.right {
        padding: 40px 0 40px;
        width: 535px;
        background: #C8AF42;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mq_max(768) {
          width: 100%;
          background: none;
          padding: 0 0 30px;
        }
        .middle {
          @include mq_max(768) {
            width: 100%;
          }
          .cvttl {
            font-weight: bold;
            color: #020255;
            font-size: 28px;
            padding: 0;
            letter-spacing: 2.24px;
            margin: 0 0 15px;
            @include mq_max(768) {
              color: #fff;
              text-align: center;
              line-height: 1.4;
            }
          }
          .txt {
            margin: 20px 0 15px;
            font-size: 14px;
            color: #020255;
            font-weight: bold;
            text-align: center;
            letter-spacing: 1.26px;
          }
          .sml {
            font-size: 10px;
            color: #020255;
            text-align: center;
          }
          .u_pc {
            >div {
              margin: 5px 0 0;
              text-align: center;
              >img {
                width: 90px;
              }
              .u_bold {
                padding: 0 0 0 10px;
                font-size: 32px;
                color: #020255;
                letter-spacing: 1.92px;
              }
            }
          }

          .telbox {
            width: 100%;
            margin: 6px 0 0;
            >.wrapbox {
              width: 100%;
              background: #C8AF42;
              text-align: center;
              @include mq_max(768) {
                padding: 15px 0;
              }
            }
            .telttl {
              color: #020255;
              font-size: 18px;
              font-weight: bold;
            }
            .en {
              color: #020255;
              margin: 5px 0 0;
              font-size: 10px;
              letter-spacing: 1.4px;
              font-weight: bold;
            }
            .click {
              margin: 8px 0 5px;
              text-align: center;
              >span {
                position: relative;
                font-size: 10px;
                color: #020255;
                font-weight: bold;
                letter-spacing: .05em;
                &:before {
                  content: '';
                  width: 10px;
                  height: 1px;
                  background: #020255;
                  position: absolute;
                  top: 7px;
                  right: -12px;
                  transform: rotate(-45deg);
                }
                &:after {
                  content: '';
                  width: 10px;
                  height: 1px;
                  background: #020255;
                  position: absolute;
                  top: 8px;
                  left: -14px;
                  transform: rotate(47deg);
                }
              }
            }
            .center {
              margin: 5px 0;
              a {
                width: 190px;
                height: 32px;
                display: inline-flex;
                color: #fff;
                background: #020255;
                border-radius: 32px;
                font-size: 13px;
                justify-content: center;
                align-items: center;
                img {
                  display: inline-block;
                  margin: 0 5px 0 0;
                }
              }
            }
            .txt {
              font-size: 12px;
            }
            .sub {
              font-size: 10px;
              text-align: center;
              margin: 3px 0 0;
              @include mq_max(768) {
                color: #fff;
                margin: 10px 0 0;
              }
            }
            .subttl {
              color: #020255;
              margin: 22px 0 5px;
              font-size: 18px;
              font-weight: bold;
              text-align: center;
              @include mq_max(768) {
                color: #fff;
              }
            }
            .fax {
              text-align: center;
              display: -webkit-inline-flex;
              display: -ms-inline-flex;
              display: inline-flex;
              @include mq_max(768) {
                color: #fff;
                display: -webkit-flex;
                display: -ms-flex;
                display: flex;
                justify-content: center;
              }
              >img {
                width: 77px;
                align-self: center;
              }
              span {
                display: inline-block;
                margin: 0 0 0 10px;
                font-size: 30px;
                color: #020255;
                white-space: nowrap;
                @include mq_max(768) {
                  color: #fff;
                  letter-spacing: .15em;
                }
              }
            }
          }
        }
      }
      >.left {
        width: 535px;
        background: url(../img/common/bg@2x.png);
        background-size: cover;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mq_max(768) {
          width: 100%;
          padding: 23px 0 30px;
        }
        .middle {
          .cvttl {
            color: #fff;
            font-size: 28px;
            font-weight: bold;
            letter-spacing: 2.8px;
            padding: 0;
            @include mq_max(768) {
              font-size: 17px;
              text-align: center;
            }
          }
          .u_lato {
            margin: 10px 0 0;
            text-align: center;
            color: #fff;
            font-size: 16px;
            letter-spacing: 2.24px;
            @include mq_max(768) {
              font-size: 10px;
            }
          }
          .c_btn_1 {
            margin: 20px auto 0;
            transition: all .2s linear;
            &:hover {
              opacity: .7;
            }
            @include mq_max(768) {
              width: 190px;
              height: 30px;
              >span {
                font-size: 13px;
                padding: 0 0 0 5px;
              }
            }
          }
        }
      }
    }
  }
}

.l_cv_en {
  position: relative;
  z-index: 2;
  margin: 150px auto 0;
  @include mq_max(768) {
    margin: 60px 0 0;
  }
  .cv {
    background: #fff;
    padding: 15px;
    @include mq_max(768) {
      padding: 30px;
      background: rgba(221,221,221,.1);
    }
    .l_flex {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      @include mq_max(768) {
        flex-wrap: wrap;
      }
      >.right {
        padding: 40px 0 40px;
        width: 535px;
        background: #C8AF42;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        @include mq_max(768) {
          width: 100%;
          background: none;
          padding: 0 0 30px;
        }
        .middle {
          @include mq_max(768) {
            width: 100%;
          }
          .cvttl {
            font-weight: bold;
            color: #020255;
            font-size: 28px;
            padding: 0;
            letter-spacing: 2.24px;
            margin: 0 0 15px;
            @include mq_max(768) {
              color: #fff;
              text-align: center;
              line-height: 1.4;
            }
          }
          .txt {
            margin: 20px 0 15px;
            font-size: 15px;
            color: #020255;
            font-weight: bold;
            text-align: center;
            letter-spacing: 1.26px;
            line-height: 1.5;
            >span {
              font-size: 13px;
            }
          }
          .sml {
            font-size: 10px;
            color: #020255;
            text-align: center;
          }
          .u_pc {
            >div {
              margin: 5px 0 0;
              text-align: center;
              >img {
                width: 90px;
              }
              .u_bold {
                padding: 0 0 0 10px;
                font-size: 32px;
                color: #020255;
                letter-spacing: 1.92px;
              }
            }
          }

          .telbox {
            width: 100%;
            margin: 6px 0 0;
            >.wrapbox {
              width: 100%;
              background: #C8AF42;
              text-align: center;
              @include mq_max(768) {
                padding: 15px 0;
              }
            }
            .telttl {
              color: #020255;
              font-size: 18px;
              font-weight: bold;
            }
            .en {
              color: #020255;
              margin: 5px 0 0;
              font-size: 10px;
              font-weight: bold;
              letter-spacing: 1.4px;
            }
            .click {
              margin: 8px 0 5px;
              text-align: center;
              >span {
                position: relative;
                font-size: 10px;
                color: #020255;
                font-weight: bold;
                letter-spacing: .05em;
                &:before {
                  content: '';
                  width: 10px;
                  height: 1px;
                  background: #020255;
                  position: absolute;
                  top: 7px;
                  right: -12px;
                  transform: rotate(-45deg);
                }
                &:after {
                  content: '';
                  width: 10px;
                  height: 1px;
                  background: #020255;
                  position: absolute;
                  top: 8px;
                  left: -14px;
                  transform: rotate(47deg);
                }
              }
            }
            .center {
              margin: 5px 0;
              a {
                width: 190px;
                height: 32px;
                display: inline-flex;
                color: #fff;
                background: #020255;
                border-radius: 32px;
                font-size: 13px;
                justify-content: center;
                align-items: center;
                img {
                  display: inline-block;
                  margin: 0 5px 0 0;
                }
              }
            }
            .txt {
              font-size: 12px;
            }
            .sub {
              font-size: 10px;
              text-align: center;
              margin: 3px 0 0;
              @include mq_max(768) {
                margin: 10px 0 0;
                color: #fff;
                line-height: 1.5;
              }
            }
            .subttl {
              color: #020255;
              margin: 22px 0 5px;
              font-size: 18px;
              font-weight: bold;
              text-align: center;
              @include mq_max(768) {
                color: #fff;
              }
            }
            .fax {
              text-align: center;
              display: -webkit-inline-flex;
              display: -ms-inline-flex;
              display: inline-flex;
              @include mq_max(768) {
                color: #fff;
                display: -webkit-flex;
                display: -ms-flex;
                display: flex;
                justify-content: center;
              }
              >img {
                width: 77px;
                align-self: center;
              }
              span {
                display: inline-block;
                margin: 0 0 0 10px;
                font-size: 30px;
                color: #020255;
                @include mq_max(768) {
                  color: #fff;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
      >.left {
        width: 535px;
        background: url(../img/common/bg@2x.png);
        background-size: cover;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mq_max(768) {
          width: 100%;
          padding: 23px 0 30px;
        }
        .middle {
          .cvttl {
            color: #fff;
            font-size: 28px;
            font-weight: bold;
            letter-spacing: 2.8px;
            padding: 0;
            @include mq_max(768) {
              font-size: 17px;
              text-align: center;
            }
          }
          .u_lato {
            margin: 10px 0 0;
            text-align: center;
            color: #fff;
            font-size: 16px;
            letter-spacing: 2.24px;
            @include mq_max(768) {
              font-size: 10px;
            }
          }
          .c_btn_1 {
            margin: 20px auto 0;
            @include mq_max(768) {
              width: 190px;
              height: 30px;
              margin: 20px auto 0;
              >span {
                font-size: 13px;
                padding: 0 0 0 5px;
              }
            }
          }
        }
      }
    }
  }
}
